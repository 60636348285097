@font-face {
  font-family: 'MsClaudy';
  src: url('../../Fonts/MsClaudy/font.woff2') format('woff2'), url('../../Fonts/MsClaudy/font.woff') format('woff');
  }
.login-lux-font {
  font-family: 'MsClaudy', 'Luxurious Script', cursive;
  font-weight: 400;
  font-size: calc(7rem);
}  
.Signincontainer {
    text-align: center;
    align-content: center;
    color: #000;
}
.signinBox {
  width: 18rem;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.btn-toolbar {
  display: flex;
  justify-content: center;
}
.signin {
  cursor: pointer;
  background-color: #fff !important;
  text-align: left;
  transition: background-color .25s;
  float: right;
  font-size: .9rem;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: -1px;
  height: 45px;
  border-radius: 0px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  width: 100%;
  max-width: 15rem;
  min-width: 15rem;
}
.signinInput {
  /* padding-left: 41.19px !important; */
  padding-right: 0px;
}
.input {
  max-width: 15rem;
  min-width: 15rem;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.signinInput {
  font-size: 1rem;
  line-height: -1px;
  height: 45px !important;
  cursor: pointer;
  background-color: #fff !important;
  text-align: left;
  color: #3E4F3D !important;
  font-family: 'Spectral', serif;
  font-size: .9rem;
}
.signinInput::placeholder {
  color: #95A482 !important;
}
.logo { 
    height: 1.2rem;
    text-align: left;
    vertical-align: -0.2rem;
    padding-right: 12px;
    padding-left: 6px;
}
.auth-main-container {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  height: 100%;
}
.cursive-font {
  font-family: 'Parisienne', cursive;
  font-size: calc(5vw + 1rem);
}
.space-between {
  justify-content: space-between;
}
.hideglass {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
.h3-text-button,
.p-text-button {
  font-family: "Spectral", serif;
  text-align: center;
  font-weight: 300;
}
.plain-link {
  color: #95A482 !important;
}
input[type="checkbox"]::-webkit-slider-thumb {
  background: #3e4f3d !important;
  accent-color: #3e4f3d !important;
}

/* All the same stuff for Firefox */
input[type="checkbox"]::-moz-range-thumb {
  background: #3e4f3d !important;
  accent-color: #3e4f3d !important;
}
/* All the same stuff for IE */
input[type="checkbox"]::-ms-thumb {
  background: #3e4f3d !important;
  accent-color: #3e4f3d !important;
}
input[type=checkbox],
input[type=radio] {
  accent-color: #3e4f3d !important;
}

.custom-control-input:checked~.custom-control-label::before, custom-control-label:before {
  color: #fff;
  border-color: #3e4f3d !important;
  background-color: #3e4f3d !important;
}
/* .custom-control-input:focus-within.custom-control-label::after {
  border-color: #3e4f3d !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(62, 79, 61, 1);
} */
.custom-control-input:checked~.custom-control-label:before
.custom-control-label:after, 
.custom-control-label:before,
.custom-control-input:checked,
.custom-control-label:focus-within
form-control:focus,
form-control::after
form-control::before,
box-shadow {
  border-color:  #3e4f3d !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(62, 79, 61, 1)  !important;
}
/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Ms Claudy by Calamar
 * URL: https://www.myfonts.com/products/regular-ms-claudy-294703
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */

 @font-face {
  font-family: 'MsClaudy';
  src: url('./Fonts/MsClaudy/font.woff2') format('woff2'), url('./Fonts/MsClaudy/font.woff') format('woff');
  }
.no-sizing-lux-font {
  font-family: 'MsClaudy', 'Luxurious Script', cursive;
  font-weight: 400;
}  
.lux-font {
  font-family: 'MsClaudy', 'Luxurious Script', cursive;
  /* font-family: 'Luxurious Script', cursive; */
  font-weight: 400;
  font-size: calc(0vw + 6rem);
  max-width: calc(100%/3);
}
.lux-font-small {
  font-family: 'MsClaudy', 'Luxurious Script', cursive;
  /* font-family: 'Luxurious Script', cursive; */
  font-weight: 400;
  font-size: calc(0vw + 4rem);
}
.x-small-text {
  font-size: calc(0vw + 0.75rem);
}
p,
.whiteInputText {
  font-size: calc(0vw + 1rem);
}
.add-margin {
  margin: 1rem 0;
}
.add-margin-after {
  margin-bottom: 0.5rem
}
.remove-top-margin {
  margin-top: 0 !important;
}
.p-text-button {
  color: #3e4f3d !important;
  font-size: calc(0vw + 1rem) !important;
  font-weight: 300 !important;
  display: inline !important;
}
/* .med-text  */
h2 {
  font-size: calc(1vw + 1rem);
}
h3,
.h3-text-button {
  font-size: calc(3.6vw + 1rem);
}

h1 {
  font-size: calc(2.16vw + 1rem);
}
.red-shadow {
  color: #9c0b4b;
  text-shadow: 1px 1px lightgrey;
}
.rose-shadow {
  color: #ae3d6e;
  text-shadow: 1px 1px lightgrey;
}
.horizontal-text-placement {
  overflow-wrap: break-word;
  min-height: fit-content;
  min-width: 18rem;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.bottom-border {
  color: #000;
  line-height: 1.6em;
  background-repeat: repeat-x;
  background-image: linear-gradient(to right, 0, 0, 0 100%, currentColor 0);
  background-size: 1px 1px;
  background-position: 0 calc(100% - 0.1em);
}
svg {
  height: 99.99px;
  width: inherit;
  padding: 0px;
  margin: 20px;
}
.overlay-image {
  border: 0;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit: cover;
}
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #3e4f3d;
  opacity: 0.6;
}
.overlay-div {
  position: absolute;
  overflow: hidden;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  min-height: 45rem;
  min-width: 18rem;
}
.overlay-div-collosseum {
  position: absolute;
  overflow: hidden;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  min-height: 24rem;
}
.overlay-text {
  opacity: 1 !important;
  color: #fff;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  display: block;
  padding: 30px 15px;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.overlay-text h1,
.overlay-text p,
.overlay-text h3,
.h3-text-button {
  color: #ffffff !important;
}
.h3-text-button:hover {
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    currentColor 100%,
    currentColor 0
  );
  background-size: 1px 1px;
  background-position: 0 calc(100% - 0.1em);
  text-decoration: none;
  text-decoration-line: none;
}
.no-margin {
  margin: 0px !important;
}
.overlay-text hr {
  color: #ffffff !important;
  border-bottom: 1px solid #fff;
  width: 65%;
}
.whiteInputText {
  color: #ffffff !important;
  width: 65%;
}
.topic-title-hr {
  color: #3e4f3d !important;
  border-bottom: 1px solid #3e4f3d;
  width: 30%;
}
.topic-title-spacing {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.center-and-block {
  margin: 2rem 0 !important;
}
hr {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.card-columns {
  column-count: 2;
}
.card-columns1 {
  column-count: 1 !important;
}
.square-card,
.card-img-top {
  border-radius: 0 !important;
}
.clickable-card {
  display: inline-block;
}
.clickable-card:hover {
  border: 1px solid rgba(62, 79, 61, 0.125);
  border-color: #3e4f3d;
  box-shadow: 0 0 0 0.2rem rgba(62, 79, 71, 0.25);
}
.text-left,
h1 .text-left,
p .text-left {
  text-align: left !important;
  font-family: "Spectral", serif;
}
.redButton {
  cursor: pointer;
  background-color: #3e4f3d;
  text-align: center;
  transition: background-color 0.25s;
  color: #fff;
  font-family: "Spectral", serif;
  font-size: 1rem;
}
.redButton:hover {
  background-color: #fff;
  color: #3e4f3d;
  font-family: "Spectral", serif;
  font-size: 1rem;
}
.text-left {
  text-align: left;
}
.whiteButton {
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  transition: background-color 0.25s;
  color: #3e4f3d;
  font-family: "Spectral", serif;
  font-size: 1rem;
  border-color: #3e4f3d;
  border-radius: 0rem !important;
}
.whiteButton:hover {
  background-color: #3e4f3d;
  color: #fff;
  font-family: "Spectral", serif;
  font-size: 1rem;
  border-color: #3e4f3d;
}
.btn-padding {
  padding: 0.375rem 0.75rem !important;
}
#root {
  overflow-x: hidden;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 62.5%;
  line-height: 1.6;
}
.col {
  padding-right: 0px;
  padding-left: 0px;
}
@media (max-width: 600px) {
  .pageContentsHidden {
    display: none !important;
  }
  .overlay-div {
    position: absolute;
    overflow: hidden;
    top: 0px;
    bottom: 0px;
    right: 15px;
    left: 15px;
    min-height: 45rem !important;
    column-count: 1;
  }
  .overlay-div-collosseum {
    position: absolute;
    overflow: hidden;
    top: 0px;
    bottom: 0px;
    right: 15px;
    left: 15px;
    min-height: 35rem !important;
    min-width: 18rem;
    column-count: 1;
  }
  .overlay-text {
    padding: 0px !important;
    transform: translate(-50%, -50%);
  }
  .card-columns {
    column-count: 1;
  }
  .add-side-margin {
    left: 15px !important;
    right: 15px !important;
  }
  .col {
    padding-right: 15px;
    padding-left: 15px;
  }
  .nav-link.p-text-button {
    color: #3e4f3d !important;
    font-size: calc(0vw + 1rem) !important;
    font-weight: 300 !important;
    display: inline !important;
  }
  .topic-title-hr {
    display: none !important;
    width: 0% !important;
  }
}
/* TAKEN FROM RSVP CONTAINER CSS */
ul,
th {
  list-style-type: none;
  font-family: "Spectral", serif;
  text-align: center;
}
.container {
  text-align: center;
}
.formGroup {
  font-family: "Spectral", serif;
  color: #3e4f3d;
  font-weight: bold;
  text-shadow: 1px 1px lightgrey;
  font-size: 1rem;
}
.formCard.hidden {
  display: none !important;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #3e4f3d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(62, 79, 61, 0.25);
}
.text-muted {
  text-shadow: none !important;
}
.formCard {
  font-family: "Spectral", serif;
  color: black;
  font-weight: bold;
  text-shadow: 1px 1px lightgrey;
  text-align: left;
  font-size: 1rem;
}
.PageButton {
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  transition: background-color 0.25s;
  color: #3e4f3d;
  font-family: "Spectral", serif;
  font-size: 1rem;
}
.PageButton:hover {
  background-color: #3e4f3d;
  color: #fff;
  font-family: "Spectral", serif;
  font-size: 1rem;
}
.SearchButton {
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  transition: background-color 0.25s;
  color: #3e4f3d;
  font-size: 1rem;
}
.SearchButton:hover {
  background-color: #3e4f3d;
  color: #fff;
  font-family: "Spectral", serif;
  font-size: 1rem;
}
.add {
  text-align: end;
}
.remove {
  background: rgba(76, 175, 80, 0);
}
.btn-group {
  padding-top: 15px;
}
.col {
  min-width: 80px !important;
  margin-top: 15px;
  margin-bottom: 15px;
}
.cardCol {
  min-width: 200px !important;
}
.address {
  min-width: 150px !important;
}
.apt {
  min-width: 50px !important;
}
.spinner-border {
  vertical-align: middle;
}
.hideSpinner {
  display: none !important;
}
.form-control,
.btn,
.btn {
  border-radius: 0 !important;
}
.flower-bud {
  height: 45px !important;
  color: #3e4f3d !important;
  fill: #3e4f3d;
}
@media (max-width: 600px) {
  .primaryForm {
    margin: 15px;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    justify-content: "center";
    display: "flex";
    align-items: "center";
    height: 100%;
  }
}
